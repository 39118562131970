/** @jsx jsx */
import { ReactElement, useEffect, useMemo } from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import Head from 'next/head';
import { MarkingProvider } from '@markings/react';
import { IntercomProvider } from 'react-use-intercom';

import { Core } from '@reckon-web/core';
import { ToastProvider } from '@reckon-web/toast';
import { DrawerProvider } from '@reckon-web/drawer';
import { UniversalNextLink } from '@reckon-web/next-link';
import { jsx } from '@reckon-web/core';
import { SideDrawerProvider } from '@reckon-web/side-drawer';

import {
  AuthProvider,
  authLink,
  errorLink,
  signin,
  useAuth,
  useUser,
} from '../components/auth';
import { Header } from '../components/header';
import { result as introspectionQueryResultData } from '../../__generated__/ts-gql/@introspection';
import { IntercomComponent } from '../components/Intercom';
import { AccessProvider } from '../components/access';

import 'react-datasheet-grid/dist/index.css';

const possibleTypes: Record<string, string[]> = {};

introspectionQueryResultData.__schema.types.forEach((supertype: any) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      (subtype: any) => subtype.name
    );
  }
});

let ApolloClientProvider = ({ children }: { children: ReactElement }) => {
  const router = useRouter();
  const user = useUser();
  const tenant = user.profile.currentTenantId || '';

  const routerPath = router.asPath;
  const gqlUrl =
    window.location.origin && window.location.origin.includes('access-aps')
      ? process.env.REACT_APP_GQL_NEW
      : process.env.REACT_APP_GQL;
  return (
    <ApolloProvider
      client={useMemo(() => {
        return new ApolloClient({
          cache: new InMemoryCache({ possibleTypes }),
          link: ApolloLink.from([
            errorLink(routerPath),
            authLink(user),
            new HttpLink({
              uri: gqlUrl,
            }),
          ]),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tenant])}
    >
      {children}
    </ApolloProvider>
  );
};

let RedirectForAuth = ({ children }: { children: ReactElement }) => {
  let router = useRouter();
  let auth = useAuth();
  const routerPath = router.asPath;

  useEffect(() => {
    if (auth.type === 'unauthenticated') {
      signin(routerPath);
    }
  }, [auth, router.pathname, routerPath]);

  if (!auth.user) {
    return null;
  }
  return <ApolloClientProvider>{children}</ApolloClientProvider>;
};

export const balanceThemeOverrides = {
  breakpoints: {
    small: 1194,
    medium: 1200,
    large: 1440,
    xlarge: 1664,
  },
};

export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <AuthProvider>
      <MarkingProvider enabled={process.env.STAGE !== 'prod'}>
        <Head>
          <title>Contacts</title>
          <link
            rel="apple-touch-icon"
            sizes="60x60"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <RedirectForAuth>
          <DrawerProvider>
            <SideDrawerProvider>
              <ToastProvider>
                <Core
                  linkComponent={UniversalNextLink}
                  theme={balanceThemeOverrides}
                >
                  {router.pathname === '/login/oauth2/code/cognito' ? (
                    <Component {...pageProps} />
                  ) : (
                    <AccessProvider>
                      <IntercomProvider appId="c9m53fof">
                        <Header />
                        <Component {...pageProps} />
                        <IntercomComponent />
                      </IntercomProvider>
                    </AccessProvider>
                  )}
                </Core>
              </ToastProvider>
            </SideDrawerProvider>
          </DrawerProvider>
        </RedirectForAuth>
      </MarkingProvider>
    </AuthProvider>
  );
}
