import React, { Fragment, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useAuth } from '../components/auth';

export const IntercomComponent = () => {
  const auth = useAuth();
  const { boot } = useIntercom();

  useEffect(() => {
    // checks whether environment is prod, and user is authenticated before booting
    if (process.env.STAGE === 'prod' && auth.user) {
      boot({
        userId: auth.user.profile['cognito:username'],
        email: auth.user.profile.email,
        name: auth.user.profile.name,
        alignment: 'left',
      });
    }
  }, [auth.user, boot]);

  return <Fragment />;
};
